@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-passion bg-secondary-light dark:bg-secondary-dark text-text-light dark:text-text-dark;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-passion font-bold text-text-light dark:text-text-dark;
  }
}

@layer components {
  .btn-primary {
    @apply bg-primary hover:bg-primary-light dark:bg-primary-dark text-black dark:text-white px-6 py-2 rounded-lg font-passion uppercase transition-colors;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
