body {
  background-color: #ffffff;
  width: 100%;
  font-family: 'Passion One', cursive;
  color: #000000;
}

.dark body {
  background-color: #1a1a1a;
  color: #ffffff;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
}

.navbar img {
  max-width: 200px;
  height: auto;
}

.hero {
  @apply flex justify-center items-center w-4/5 mx-auto;
}

.hero-bg {
  background-image: url('./corp-bg.webp');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 500px;
  border-radius: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
}

.dark .hero-bg {
  background-color: #333333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Passion One', cursive;
  font-weight: 700;
}

.hero-bg h2 {
  text-transform: uppercase;
  font-size: 30px;
  color: white;
  margin-bottom: 20px;
}

.hero-bg h1 {
  font-size: 55px;
  color: white;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.hero-bg h1 span {
  color: #FFD700;
}

.hero-bg button {
  background-color: #FFD700;
  color: black;
  border: none;
  padding: 10px 25px;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  margin-top: 20px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutUs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 60%;
  flex-direction: column;
}

.aboutUs p {
  margin-bottom: 10px;
}
.aboutUs h2 {
  @apply text-4xl text-text-light dark:text-text-dark;
  margin-bottom: 20px;
}

.aboutUs p {
  @apply text-base font-inter text-text-light dark:text-text-dark;
}

.servers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.servers h2 {
  @apply text-4xl text-text-light dark:text-text-dark;
  margin-bottom: 20px;
}

.server-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
}

.server-card {
  background-color: #ffffff; /* Light theme server card background */
  border: 1px solid #e0e0e0; /* Light theme server card border */
  color: #000000; /* Light theme server card text color */
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 0 0 calc(33.333% - 20px);
  margin-bottom: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 550px;
}

.server-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.server-img-bg {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  background-color: #2c2c2c;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.server-img-bg img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  object-fit: contain;
  transition: transform 0.3s;
}

.server-img-bg:hover img {
  transform: translate(-50%, -50%) scale(1.05);
}

.server-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 100%;
}

.server-info h3 {
  font-size: 1.75rem;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.server-info p {
  font-size: 1rem;
  margin: 5px 0;
  text-align: center;
}

.server-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #FFD700;
  width: 100%;
}

.server-header h3 {
  @apply text-3xl text-left flex justify-between font-passion text-text-light dark:text-text-dark;
}

.server-social {
  @apply flex gap-2;
}

.server-social i {
  @apply text-base cursor-pointer p-2 rounded-lg flex justify-center items-center transition-opacity hover:opacity-90;
}

.server-social a {
  color: #FFD700;
  font-size: 24px;
  transition: color 0.3s;
}

.server-social a:hover {
  color: #4CAF50;
}

.server-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}

.server-footer button {
  font-size: 24px;
  padding: 12px 24px;
  background-color: #4CAF50;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.server-footer button:hover {
  background-color: #FFC107;
}

.server-players {
  font-size: 1rem;
  margin: 0;
  text-align: right;
}

.server-players span {
  @apply text-primary;
}

a {
  text-decoration: none;
}

.footer {
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 40px 0 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.footer-logo img {
  height: 50px;
}

.footer-links ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #4CAF50;
}

.footer-social {
  display: flex;
  gap: 15px;
}

.footer-social a {
  color: #ffffff;
  font-size: 24px;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #4CAF50;
}

.footer-bottom {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #333333;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo, .footer-links, .footer-social {
    margin-bottom: 20px;
  }

  .footer-links ul {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 900px) {
  .server-card {
    @apply flex-col items-center justify-center h-auto;
  }

  .server-img-bg {
    @apply w-full bg-transparent;
  }

  .server-info {
    @apply w-full bg-transparent;
  }

  .server-social {
    @apply hidden;
  }

  .server-header, .server-footer {
    @apply justify-center;
  }
}

.logo-carousel {
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.logo-slide {
  display: flex;
  position: absolute;
  animation: infiniteScroll 40s linear infinite;
}

.logo-slide > div {
  transition: all 0.3s ease;
}

.logo-slide > div img {
  filter: grayscale(100%);
  opacity: 0.5;
  transition: all 0.3s ease;
  height: 64px;
  width: auto;
  object-fit: contain;
}

.logo-slide > div:hover img {
  filter: grayscale(0%);
  opacity: 1;
}

@keyframes infiniteScroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .logo-slide img {
    height: 40px;
  }
}

@media (max-width: 480px) {
  .logo-slide img {
    height: 32px;
  }
  .logo-slide > div {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.logo-carousel-container {
  width: 80%;
  margin: 0 auto;
}

.logo-carousel-container .slick-track {
  display: flex;
  align-items: center;
}

.logo-carousel-container .slick-slide {
  height: auto;
}

.logo-carousel-container .slick-slide img {
  object-fit: contain;
}

.logo-carousel-container .slick-arrow {
  display: none !important;
}

.coming-soon {
  @apply flex justify-center items-center flex-col text-center my-32;
}

.coming-soon h2 {
  @apply text-4xl text-text-light dark:text-text-dark mb-10;
}

.coming-soon-cards {
  display: grid;
  gap: 1.5rem;
  padding: 1rem;
}

.coming-soon-card {
  background-color: #2c2c2c;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 500px;
}

.coming-soon-card img {
  width: 160px;
  height: 160px;
  object-fit: contain;
  margin: 0 auto 24px;
  border-radius: 50%;
}

.coming-soon-card h3 {
  font-size: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.coming-soon-card p {
  flex-grow: 1;
  margin-bottom: 16px;
  text-align: center;
}

.coming-soon-card .expected-date {
  margin-bottom: 24px;
  color: #FFD700;
  font-weight: bold;
  font-size: 18px;
}

.coming-soon-card button {
  padding: 12px 36px;
  border-radius: 8px;
  text-transform: uppercase;
  font-family: 'Passion One', cursive;
  font-size: 20px;
  cursor: not-allowed;
  opacity: 0.8;
  transition: all 0.3s;
}

.coming-soon-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100%;
}

.coming-soon-info h3 {
  font-size: 1.75rem;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.coming-soon-info p {
  font-size: 1rem;
  margin: 5px 0;
  text-align: center;
}

.coming-soon-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}

.coming-soon-footer button {
  font-size: 24px;
  padding: 12px 24px;
  background-color: #4CAF50;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.coming-soon-footer button:hover {
  background-color: #FFC107;
}

.coming-soon-players {
  font-size: 1rem;
  margin: 0;
  text-align: right;
}

@media (max-width: 640px) {
  .coming-soon-card {
    height: auto;
    min-height: 450px;
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .hero-bg h1 {
    font-size: 32px;
    line-height: 1.4;
  }
  
  .hero-bg h2 {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .server-card {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }

  .server-info {
    flex: 1;
  }

  .server-info h3 {
    font-size: 1.5rem;
  }

  .server-info p {
    font-size: 1rem;
  }

  .server-footer {
    flex-direction: column;
    align-items: center;
  }

  .server-footer button {
    width: 100%;
    margin-bottom: 10px;
  }

  .server-players {
    text-align: left;
  }
}

.dark .server-card {
  background-color: #2c2c2c; /* Dark theme server card background */
  border: 1px solid #444444; /* Dark theme server card border */
  color: #ffffff; /* Dark theme server card text color */
}

/* Language Selector Styles */
.lang-selector {
  position: relative;
  z-index: 50;
}

.lang-button {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border: 2px solid #ffffff30;
  border-radius: 8px;
  background: transparent;
  color: inherit;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.lang-button:hover {
  border-color: #ffffff60;
  transform: translateY(-1px);
}

.lang-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  overflow: hidden;
  min-width: 120px;
}

.dark .lang-dropdown {
  background: #2c2c2c;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
}

.lang-option {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  border: none;
  background: transparent;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dark .lang-option {
  color: white;
}

.lang-option:hover {
  background: #f5f5f5;
}

.dark .lang-option:hover {
  background: #3c3c3c;
}

.dark .lang-button {
  border-color: #ffffff30;
  color: white;
}

.dark .lang-button:hover {
  border-color: #ffffff60;
}

/* Add Font Awesome icon styles */
.fa-globe {
  font-size: 12px;
  opacity: 0.8;
}

.flag {
  font-size: 16px;
  line-height: 1;
}

.lang-name {
  margin-left: 4px;
}

/* Ghost Loader Styles */
.ghost-loader {
  width: 100%;
}

.ghost-hero-bg {
  width: 100%;
  height: 500px;
  border-radius: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
}

.dark .ghost-hero-bg {
  background: rgba(255, 255, 255, 0.05);
}

.ghost-server-card {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark .ghost-server-card {
  background: #2c2c2c;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

p {
  letter-spacing: 0.02em;  /* veya 0.3px */
}

/* Dark mode için ayrı bir değer istersen */
.dark p {
  letter-spacing: 0.025em;  /* veya 0.4px */
}